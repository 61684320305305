<!--考生考场安排新增  -->
<template>
    <div class="AddArrange">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">考生考场安排新增</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form ref="form" :model="form" id="form-1" :rules="rules" class="form" inline label-width="150px">
            <el-form-item label="安排编号">
                <el-input  v-model="form.planNum" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="考场类型">
                <el-select v-model="form.roomType" placeholder="请选择">
                    <el-option
                      v-for="item in form.roomTypeChoose"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="安排日期" prop="planDate">
                <el-date-picker
                    v-model="form.planDate"
                    type="date"
                    placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="监考老师" prop="Invigilator">
                <el-input v-model="form.Invigilator" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="A分组码">
                <el-input v-model="form.Acode" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="B分组码">
                <el-input v-model="form.Bcode" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="安排单状态">
                <el-select v-model="form.planState" placeholder="请选择">
                    <el-option
                      v-for="item in form.planStateChoose"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="等级">
                <el-input v-model="form.grade" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="场地地址" prop="address">
                <el-input v-model="form.address" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="选择场地">
                <el-select v-model="form.field" placeholder="请选择">
                    <el-option
                      v-for="item in form.fieldChoose"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="机构/学科">
                <el-input v-model="form.mechanism" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="考场类B">
                <el-input v-model="form.testRoomB" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="科目">
                <el-input v-model="form.subject" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="考场人数">
                <el-input v-model="form.testpeople" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="起止时间">
                <el-time-picker
                    is-range
                    v-model="form.startStop"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围">
                  </el-time-picker>
            </el-form-item>
            <el-form-item label="时长" >
                <el-input v-model="form.duration" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="简要备注">
                <el-input type="textarea" class="textArea-width" v-model="form.remarks" placeholder="请输入"></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <!-- <el-button style="background-color: #29726C;color: #FFFFFF;" @click="addstu">添加学员</el-button> -->
            <el-button style="background-color: #18BC9C;color: #FFFFFF;"  @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
               form:{
                   planNum:"",//安排编号
                   roomType:"",//考场类型
                   roomTypeChoose:[
                       {label: '单科考场',value:'1'}, 
                       {label: '综合考场',value:'2'},
                   ],//考场类型选择
                   planDate:"",//安排日期
                   Invigilator:"",//监考老师
                   Acode:"",//A分组码
                   Bcode:"",//B分组码
                   planState:"",//安排状态
                   planStateChoose:[
                       {label: '准备',value:'1'}, 
                       {label: '考试',value:'2'}, 
                       {label: '取消',value:'3'},
                   ],//安排单状态选择
                   grade:"",//等级
                   address:"",//场地地址
                   field:"",//选择场地
                   fieldChoose:[],//选择场地选项
                   mechanism:"",//机构/学科
                   testRoomB:"",//考场类B
                   subject:"",//科目
                   testpeople:"",//考场人数
                   startStop:[new Date(), new Date()],//起止时间
                   duration:"",//时长
                   remarks:""//简要备注
               },
               rules:{
                  planDate:[
                       { required: true, message: '安排日期不能为空', trigger: 'change' },
                  ],
                  Invigilator:[
                       { required: true, message: '监考老师不能为空', trigger: 'blur' },
                  ],
                  address:[
                       { required: true, message: '场地地址不能为空', trigger: 'blur' },
                  ],
               }
            }
        },
        created() {
            
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) {//提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        console.log(this.form)
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) {//取消
                this.goBack()
                this.$refs[form].resetFields();
            },
            addstu(){//添加学员
                this.$router.push({
                    path: "/student/addStudentData"
                })
            }
        }
    }
</script>

<style scoped="scoped">
    .AddArrange{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 39px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    
    .el-select {
        width: 300px;
    }
    .el-range-editor.el-input__inner{
        width: 300px;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
